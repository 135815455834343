import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer-container">
      <div className="column">
        <ul>
          <li><Link className='footer-link' to="/">{t('hero.home')}</Link></li>
          <li><Link className='footer-link' to="/study">{t('hero.study')}</Link></li>
          <li><Link className='footer-link' to="/about">{t('hero.about')}</Link></li>
          <li><Link className='footer-link' to="/contact">{t('hero.contact')}</Link></li>
        </ul>
      </div>
      <div className="column">
        <h5>Download</h5>
        <ul>
          <li>Android</li>
          <li>IOS</li>
        </ul>
      </div>
      <div className="column">
        <ul>
          <li><FontAwesomeIcon icon={faEnvelope} />support@zunbur.com</li>
          <li>123 Address St, City</li>
        </ul>
      </div>
      <div className="line"></div>
      <div className="copyright">
        <p>Copyright &copy; 2023 Zunbur</p>
      </div>
    </footer>
  );
}

export default Footer;
