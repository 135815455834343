import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ContentSelector.css';

function ContentSelector(props) {
    const [topics, setTopics] = useState([])
    const handleClick = (event) => {
      const value = event.currentTarget.getAttribute("data-value"); 
      props.callback(value);
    }
    useEffect(() => {
        const fetchAllTopics = async () => {
            try {
                const res = await axios.get("http://localhost:8800/topics")
                setTopics(res.data);
            } catch(err) {
                console.log(err);
            }
        }
        fetchAllTopics();
    }, [])

    for(var i = 0; i < topics.length; i++) {
      console.log('This is ' + topics[i])
    }

  return (
    <div id='topics-container'>
        <div className='topics'>
        {topics.map(topic => (
          <div className='topic'>
            <button className='topic-button' data-value={topic.prompt} onClick={handleClick}>{topic.name}</button>
            <br />
          </div>
        ))}
        </div>
    </div>
  )
}

export default ContentSelector;
