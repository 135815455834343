import React, { Component, useState } from 'react';
import '../App.css';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from "react-i18next";
import '../i18n';
import LanguageSelector from './LanguageSelector';
import i18n from '../i18n';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
// import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const Navbar1 = () => {
  const handleClickScroll = () => {
    const element = document.getElementById('about');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Navbar id='navbar-parent' fixed='top' expand="lg" variant='dark'>
      <Container fluid id='navbar-container'>
        <Navbar.Brand id='navbar-brand' href="/">Zunbur</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav activeKey={location.pathname} id='nav-options' className="me-auto" navbarScroll>
            <Nav.Link href="/">{t('hero.home')}</Nav.Link>
            <Nav.Link href="/study">{t('hero.study')}</Nav.Link>
            <Nav.Link href="/about">{t('hero.about')}</Nav.Link>
            <Nav.Link href="/contact">{t('hero.contact')}</Nav.Link>
          </Nav>
          <dir id='language-container'>
              <LanguageSelector />
          </dir>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default withTranslation()(Navbar1);
