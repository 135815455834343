import React, { useState } from 'react';
import '../App.css';
import './StudyInterface.css';
import ContentSelector from './ContentSelector';
import StudyContent from './StudyContent';

const StudyInterface = () => {
  const [pickedTopic, setPickedTopic] = useState('alphabet');
  function changeTopic(prompt) {
    setPickedTopic(prompt);
  }

  return (
    <div id='study-interface'>
        <ContentSelector callback={changeTopic} />
        {/* <div>{pickedTopic}</div> */}
        <StudyContent pickedTopic= {pickedTopic} />
    </div>
  );
}

export default StudyInterface;
