import React from 'react';
import '../App.css';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ContactForm from '../components/ContactForm';

function Contact() {
  return (
    <>
      <Navbar />
      <ContactForm />
      <Footer />
    </>
  )
}

export default Contact;