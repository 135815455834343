import React from 'react';
import './AboutSection.css';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { useTranslation } from "react-i18next";

function AboutSection() {
  const {i18n} = useTranslation();

  return (
    <div id='about-section'>
        <div className='inter'></div>
        <div className='heading-container'>
            <h2>{t("about.title")}</h2>
        </div>
        <div className='contents'>
          <div className='text-container'>
            <p className='standard-text'>{t("about.text")}</p>
          </div>
          <div className='image-container'>
              <img src='/images/desert.jpg' alt='a desk in the desert'></img>
          </div>
        </div>
    </div>
  )
}

export default AboutSection;
