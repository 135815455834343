import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownButton, Dropdown } from "react-bootstrap";
import './LanguageSelector.css';

const LanguageSelector = () => {
    const {i18n} = useTranslation();
    const { t } = useTranslation();
    const [language, setLanguage] = useState('en');
    const displayedLang = language === 'en' ? "English" : (language === 'ba' ? "Bosanski" : "Deutsch");

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    }

    return (
        <Dropdown className="language-selector bg-transparent">
            <DropdownButton className="dropdown-button" title={i18n.language === 'en' ? "English" : (i18n.language === 'ba' ? 'Bosanski' : 'Deutsch')} variant="light">
                <Dropdown.Item className="dropdown-item" onClick={() => handleLanguageChange('en')} >English</Dropdown.Item>
                <Dropdown.Item className="dropdown-item" onClick={() => handleLanguageChange('ba')} >Bosanski</Dropdown.Item>
                <Dropdown.Item className="dropdown-item" onClick={() => handleLanguageChange('de')} >Deutsch</Dropdown.Item>
            </DropdownButton>
        </Dropdown>
    );
};

export default LanguageSelector;