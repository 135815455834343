import React, { useState } from 'react';
import './ContactForm.css';
import axios from 'axios';

const ContactForm = () => {
  // State to manage form data
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Function to handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to your server
      const response = await axios.post('/send-email', contactFormData);
      console.log(response.data);
      // Optionally, you can show a success message to the user
    } catch (error) {
      console.error('Error sending email:', error);
      // Optionally, you can show an error message to the user
    }

    // Clear form data after submission
    setContactFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <form className='contactForm' onSubmit={handleSubmit}>
      <label>
        Name:
        <input
          type="text"
          name="name"
          value={contactFormData.name}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <label>
        Email:
        <input
          type="email"
          name="email"
          value={contactFormData.email}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <label>
        Message:
        <textarea
          name="message"
          value={contactFormData.message}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <button type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;
