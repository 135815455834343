import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Study from './pages/Study';
import Contact from './pages/Contact';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/study" exact element={<Study />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
