import React from 'react';
import '../App.css';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import AboutSection from '../components/AboutSection';

function About() {
  return (
    <>
      <Navbar />
      <AboutSection />
      <Footer />
    </>
  )
}

export default About;
