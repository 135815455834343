import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation, withTranslation } from "react-i18next";
import './StudyContent.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function StudyContent() {
    const [letters, setLetters] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchAllLetters = async () => {
            try {
                const res = await axios.get("http://localhost:8800/letters")
                setLetters(res.data);
            } catch(err) {
                console.log(err);
            }
        }
        fetchAllLetters();
    }, [])

  return (
    <div id='contents-container'>
      <div className='intro'>
        <h1>{t('study.alphabet.intro.head')}</h1>
        <p>{t('study.alphabet.intro.body')}</p>
      </div>
      <Container id='letters-table'>
        <Row>
          {letters.map(letter => (
            <Col lg={2} className='letter' style={{backgroundColor: letter.id % 2 == 1 ? 'lightgray' : 'gray'}}>
              <h1 className='letter-inner arabic-letter' dir='rtl'>{letter.arabic}</h1>
              <h2 className='letter-inner'>{letter.english}</h2>
            </Col>
          ))} 
        </Row>
      </Container>
    </div>
  )
}

export default StudyContent;
