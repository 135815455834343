import React, { useState, useEffect } from 'react';
import '../App.css';
import './Hero.css';
import { useTranslation } from "react-i18next";

function HomeSection() {
    const { t } = useTranslation();
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [ scrollOffset, setScrollOffset ] = useState(0);

    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    const handleScroll = () => {
      setScrollOffset(window.scrollY);
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <div className="home-container" onMouseMove={handleMouseMove}>
        <div className='base-hero'>
          <div className='overlay-area'>
            <h1>{t('home.hero.line1')}</h1>
            <h1>{t('home.hero.line2')}</h1>
            <h2>{t('home.hero.line3')}</h2>
          </div>
          <div 
          className='arabic-hero'
          style={{
            '--cursor-x': `${cursorPosition.x}px`,
            '--cursor-y': `${cursorPosition.y}px`,
            '--scroll-offset': `${scrollOffset}px`,
            }}>
          <h1 dir='rtl'>يا ترى كيف لو</h1>
          <h1 dir='rtl'>كنت تتحدث العربية؟</h1>
          <h2 dir='rtl'>هيا بنا نحققه!</h2>
        </div>
        </div>
    </div>
  )
}

export default HomeSection;