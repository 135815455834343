import React, { useState, useEffect } from 'react';
import './Info.css';
import { useTranslation } from "react-i18next";


const Info = () => {
  const [isVisible, setIsVisible] = useState(false, false, false);
  const { t } = useTranslation();

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const targetPositions = [
      document.getElementById('info-box-1').offsetTop,
      document.getElementById('info-box-2').offsetTop,
      document.getElementById('info-box-3').offsetTop
    ];

    const newVisibility = targetPositions.map((position, index) => scrollPosition > position);

    setIsVisible(newVisibility);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='info-container'>
      <div 
      className={`info-box slide-in-left ${isVisible[0] ? 'visible' : ''}`}
      id='info-box-1'>
        <h2>{t('home.info.box1.head')}</h2>
        <p>{t('home.info.box1.body')}</p>
      </div>
      <div 
      className={`info-box slide-in-right ${isVisible[1] ? 'visible' : ''}`} 
      id='info-box-2'>
        <h2>{t('home.info.box2.head')}</h2>
        <p>{t('home.info.box2.body')}</p>
      </div>
      <div 
      className={`info-box slide-in-left ${isVisible[2] ? 'visible' : ''}`} 
      id='info-box-3'>
        <div id='free-text'>
          <h2>{t('home.info.box3.head')}</h2>
          <p>{t('home.info.box3.body')}</p>
        </div>
        <button className='primary-btn'>
          {t('home.info.box3.cta')}
        </button>
      </div>
      <div className='signup-box'>
        
      </div>
    </div>
  )
}

export default Info;